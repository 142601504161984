import info from "../data/info.json"

function CopyrightSection({...args}) {
    return (
        <div style={{paddingTop: "20px", paddingBottom: "20px", marginBottom: "40px", backgroundColor: "lightgray"}}>
            <p style={{textAlign: "center", fontSize: "18px"}}>© 2024-2025 · {info.brandName} · All Rights Reserved.</p>
        </div>
    )
}

export default CopyrightSection;