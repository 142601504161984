import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Label,
  FormGroup,
  Input,
  Dropdown,
} from 'reactstrap';
import info from "../data/info.json";

function Header({...args}) {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1111);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1111);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    !isSmallScreen ? <div>
      <Navbar {...args} style={{display: "flex", marginLeft: "10em", marginRight: "10em"}}>
        <NavbarBrand href="/">{info.brandName}</NavbarBrand>
        {/* <NavbarToggler onClick={toggle} /> */}
        <Nav className="d-flex justify-content-between">
          <NavItem>
            <NavLink href="/">Option 1</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/">
              Option 2
            </NavLink>
          </NavItem>
          <Dropdown onMouseEnter={toggle} onMouseLeave={toggle} isOpen={dropdownOpen} toggle={() => {}} direction="down" nav>
            <DropdownToggle caret nav>Option 3</DropdownToggle>
            <DropdownMenu {...args}>
              <DropdownItem header>Header</DropdownItem>
              <DropdownItem href='/'>Action 1</DropdownItem>
              {/* <DropdownItem text>Dropdown Item Text</DropdownItem>
              <DropdownItem disabled>Action (disabled)</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Foo Action</DropdownItem>
              <DropdownItem>Bar Action</DropdownItem>
              <DropdownItem>Quo Action</DropdownItem> */}
            </DropdownMenu>
          </Dropdown>
        </Nav>
        <div>
          <Input
            id="exampleSearch"
            name="search"
            placeholder="Search"
            type="search"
          />
        </div>
      </Navbar>
    </div> : 
    <div>
      <Navbar color="faded" light>
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <NavbarBrand href="/" className="me-auto">
        {info.brandName}
        </NavbarBrand>
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink href="/components/">Option 1</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://github.com/reactstrap/reactstrap">
                Option 2
              </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Option 3
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem>Option 1</DropdownItem>
                <DropdownItem>Option 2</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Reset</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
        <Input
          style={{marginTop: "10px"}}
          id="exampleSearch"
          name="search"
          placeholder="Search"
          type="search"
        />
      </Navbar>
    </div>
  );
}

export default Header;