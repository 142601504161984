import { useEffect, useState } from "react";
import PageTitle from "./PageTitle";

function Footer({...args}) {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1111);

    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 1111);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
        !isSmallScreen ? <div style={{padding: "50px 20vw", backgroundColor: "#f9f9f9"}}>
            <div style={{display: "flex"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: "40px", borderRight: "darkgray 1px solid", width: "35vw"}}>
                    <div style={{marginBottom: "25px"}}>
                        <img src="https://picsum.photos/id/222/300/200"/>
                    </div>
                    <div style={{marginBottom: "25px"}}>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque sit amet magna at tempor. Cras laoreet libero nec nibh cursus, quis fringilla sem vestibulum.
                        </p>
                        <p>
                        Sed dignissim ipsum nibh, non faucibus enim eleifend sit amet. Morbi volutpat ut ante ut posuere. Fusce lobortis consectetur aliquet. Fusce pretium dui eget eros blandit, in sodales tortor finibus. Cras sit amet ullamcorper tellus.
                        </p>
                    </div>
                    <div>

                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{display: "flex", marginLeft: "25px", padding: "25px", borderBottom: "darkgray 1px solid"}}>
                        <div style={{display: "flex", flexDirection: "column", marginRight: "10vw"}}>
                            <div style={{display: "flex", flexDirection: "column", marginBottom: "25px"}}>
                            <PageTitle size={3} bold>About</PageTitle>
                                <a>About Us</a>
                                <a>Contact Us</a>
                                <a>Privacy Policy</a>
                            </div>
                            <div style={{display: "flex", flexDirection: "column"}}>
                            <PageTitle size={3} bold>Other</PageTitle>
                                <a>Responsible Gambling</a>
                                <a>Gambling Addiction</a>
                                <a>Sitemap</a>
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                        <PageTitle size={3} bold>Information</PageTitle>
                            <a>Promotion and Bonuses</a>
                            <a>Casino Review</a>
                            <a>Best Casino</a>
                            <a>Providers</a>
                            <a>Casino Games</a>
                            <a>4D</a>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: "25px"}}>
                        <div>
                            <img src="https://picsum.photos/id/222/300/200"/>
                        </div>
                        <p style={{textAlign: "center"}}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque sit amet magna at tempor. Cras laoreet libero nec nibh cursus, quis fringilla sem vestibulum.
                        </p>
                    </div>
                </div>
            </div>
        </div> : 
        <div style={{padding: "50px 20vw", backgroundColor: "#f9f9f9"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{marginBottom: "25px"}}>
                        <img src="https://picsum.photos/id/222/300/200"/>
                    </div>
                    <div style={{marginBottom: "25px"}}>
                        <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque sit amet magna at tempor. Cras laoreet libero nec nibh cursus, quis fringilla sem vestibulum.
                        </p>
                        <p>
                        Sed dignissim ipsum nibh, non faucibus enim eleifend sit amet. Morbi volutpat ut ante ut posuere. Fusce lobortis consectetur aliquet. Fusce pretium dui eget eros blandit, in sodales tortor finibus. Cras sit amet ullamcorper tellus.
                        </p>
                    </div>
                    <div>

                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div style={{display: "flex", padding: "25px", borderBottom: "darkgray 1px solid"}}>
                        <div style={{display: "flex", flexDirection: "column", marginRight: "10vw"}}>
                            <div style={{display: "flex", flexDirection: "column", marginBottom: "25px"}}>
                            <PageTitle size={3} bold>About</PageTitle>
                                <a>About Us</a>
                                <a>Contact Us</a>
                                <a>Privacy Policy</a>
                            </div>
                            <div style={{display: "flex", flexDirection: "column"}}>
                            <PageTitle size={3} bold>Other</PageTitle>
                                <a>Responsible Gambling</a>
                                <a>Gambling Addiction</a>
                                <a>Sitemap</a>
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                        <PageTitle size={3} bold>Information</PageTitle>
                            <a>Promotion and Bonuses</a>
                            <a>Casino Review</a>
                            <a>Best Casino</a>
                            <a>Providers</a>
                            <a>Casino Games</a>
                            <a>4D</a>
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: "25px"}}>
                        <div>
                            <img src="https://picsum.photos/id/222/300/200"/>
                        </div>
                        <p style={{textAlign: "center"}}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque sit amet magna at tempor. Cras laoreet libero nec nibh cursus, quis fringilla sem vestibulum.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;