import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Container, Label, Row } from "reactstrap";

const items = [
    {
      src: 'https://picsum.photos/id/123/300/200',
      altText: 'Title 1',
      title: 'Title 1',
      subtitle: 'Subtitle 1',
      text: "Sample text...",
      key: 1,
    },
    {
      src: 'https://picsum.photos/id/456/300/200',
      altText: 'Title 2',
      title: 'Title 2',
      subtitle: 'Subtitle 2',
      text: "Sample text...",
      key: 2,
    },
    {
      src: 'https://picsum.photos/id/412/300/200',
      altText: 'Title 3',
      title: 'Title 3',
      subtitle: 'Subtitle 3',
      text: "Sample text...",
      key: 3,
    },
    {
        src: 'https://picsum.photos/id/222/300/200',
        altText: 'Title 4',
        title: 'Title 4',
        subtitle: 'Subtitle 4',
        text: "Sample text...",
        key: 4,
      },
      {
        src: 'https://picsum.photos/id/111/300/200',
        altText: 'Title 5',
        title: 'Title 5',
        subtitle: 'Subtitle 5',
        text: "Sample text...",
        key: 5,
      },
      {
        src: 'https://picsum.photos/id/344/300/200',
        altText: 'Title 6',
        title: 'Title 6',
        subtitle: 'Subtitle 6',
        text: "Sample text...",
        key: 6,
      },
  ];

const bonuses = items.map((item) => {
    return (
        <Col
        style={{
            width: '18rem'
        }}>
            <Card
            key={item.key}
            style={{
                margin: '10px'
            }}
            >
                <img
                    alt={item.altText}
                    src={item.src}
                />
                <CardBody>
                    <CardText>
                        {item.text}
                    </CardText>
                </CardBody>
            </Card>
        </Col>
    );
})

function PostSection({...args}) {
    return (
        <div style={{display: "flex", alignItems: "center", flexDirection: "column", margin: "25px 0px"}}>
            <span style={{fontSize: "1.6rem", fontWeight: "bold"}}>Post Section</span>
            <Container>
                <Row>
                    {bonuses}
                </Row>
            </Container>
        </div>
    );
}

export default PostSection;