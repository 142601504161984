import './App.css';
import HomeCarousel from './component/HomeCarousel';
import BonusSection from './component/BonusSection';
import PostSection from './component/PostSection';
import Footer from './component/Footer';
import OurTeamSection from './component/OurTeamSection';
import Header from './screens/Header';
import CopyrightSection from './component/CopyrightSection';
import HorizontalCard from './component/HorizontalCard';
import { Button } from 'reactstrap';
import CompanyDetailsSection from './screens/CompanyDetailsSection';

function App() {
  return (
    <div>
      <Header/>
      <HomeCarousel/>
      <CompanyDetailsSection/>
      <PostSection/>
      <OurTeamSection/>
      <Footer/>
      <CopyrightSection/>
      
    </div>
  );
}

export default App;
