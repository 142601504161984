import { Col, Container, Row } from "reactstrap";
import HorizontalCard from "../component/HorizontalCard";

function CompanyDetailsSection({...args}) {
    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", margin: "25px 18vw"}}>
            <span style={{fontSize: "1.6rem", fontWeight: "bold"}}>Collaborators</span>
            <HorizontalCard style={{marginBottom: "40px"}}/>
            <HorizontalCard style={{marginBottom: "40px"}}/>
            <HorizontalCard style={{marginBottom: "40px"}}/>
        </div>
    );
}

export default CompanyDetailsSection;