import { useEffect, useState } from "react";
import PageTitle from "./PageTitle";
import PersonInfo from "./PersonInfo";

function OurTeamSection({...args}) {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1111);

    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 1111);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
        !isSmallScreen ?
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: "50px 20vw"}}>
            <div style={{marginBottom: "25px"}}>
                <PageTitle size={3} bold>Our Editorial Team</PageTitle>
            </div>
            <div style={{display: "flex"}}>
                <PersonInfo height={"200px"} width={"200px"} src={"https://picsum.photos/id/423/200/200"} name={"Name 1"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque sit amet magna at tempor."}/>
                <PersonInfo height={"200px"} width={"200px"} src={"https://picsum.photos/id/423/200/200"} name={"Name 2"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque sit amet magna at tempor."}/>
                <PersonInfo height={"200px"} width={"200px"} src={"https://picsum.photos/id/423/200/200"} name={"Name 3"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque sit amet magna at tempor."}/>
                <PersonInfo height={"200px"} width={"200px"} src={"https://picsum.photos/id/423/200/200"} name={"Name 4"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque sit amet magna at tempor."}/>
            </div>
        </div> : 
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: "50px 20vw"}}>
            <div style={{marginBottom: "25px"}}>
                <PageTitle size={3} bold>Our Editorial Team</PageTitle>
            </div>
            <div style={{display: "flex", flexDirection: "column"}}>
                <PersonInfo height={"200px"} width={"200px"} src={"https://picsum.photos/id/423/200/200"} name={"Name 1"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque sit amet magna at tempor."}/>
                <PersonInfo height={"200px"} width={"200px"} src={"https://picsum.photos/id/423/200/200"} name={"Name 2"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque sit amet magna at tempor."}/>
                <PersonInfo height={"200px"} width={"200px"} src={"https://picsum.photos/id/423/200/200"} name={"Name 3"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque sit amet magna at tempor."}/>
                <PersonInfo height={"200px"} width={"200px"} src={"https://picsum.photos/id/423/200/200"} name={"Name 4"} description={"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pellentesque sit amet magna at tempor."}/>
            </div>
        </div>
    )
}

export default OurTeamSection;