import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";

function HorizontalCard({...args}) {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 750);

    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 750);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    return (
        !isSmallScreen ? <div style={{...cardContainer, ...args.style}}>
            <div style={{...itemContainer}}>
                <img style={{...roundEdgeImg}} src="https://picsum.photos/id/423/125/125"/>
            </div>
            <div style={{...itemContainer}}>
                <img src={require("../images/casino.png")}/>
                <label>Title 1</label>
                <p>Description 1</p>
            </div>
            <div style={itemContainer}>
                <img src={require("../images/cash.png")}/>
                <label>Title 2</label>
                <p>Description 2</p>
            </div>
            <div style={itemContainer}>
                <img src={require("../images/rotate.png")}/>
                <label>Title 3</label>
                <p>Description 3</p>
            </div>
            <div style={{...itemContainer, borderRight: "none", justifyContent: "center"}}>
                <Button style={{...cardButton}} color="primary">Claim</Button><br/>
                <Button style={{...cardButton}} color="primary" outline>Guide</Button>
            </div>
        </div> : 
        <div style={{...cardContainer, ...args.style, minWidth: "300px", gridTemplateColumns: "1fr 1fr 1fr"}}>
            <div style={{...itemContainer, borderRight: "none"}}>
                <img style={{...roundEdgeImg, width: "80%"}} src="https://picsum.photos/id/423/125/125"/>
            </div>
            <div style={{...itemContainer, borderRight: "none"}}>
                <label>Title 2</label>
                <p>Description 2</p>
            </div>
            <div style={{...itemContainer, borderRight: "none", justifyContent: "center"}}>
                <Button style={{...cardButton}} color="primary">Claim</Button><br/>
                <Button style={{...cardButton}} color="primary" outline>Guide</Button>
            </div>
        </div>
    )
}

export default HorizontalCard;

const cardContainer = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    gridTemplateRows: "auto",
    width: "100%",
    minWidth: "700px",
    borderRadius: 20,
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)"
}

const roundEdgeImg = {
    borderRadius: 20, 
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)"
}

const itemContainer = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRight: "2px solid lightgray",
    padding: "5px 0px",
    margin: "20px 0px"
}

const cardButton = {
    width: "60%",
    minWidth: "75px",
    borderRadius: "15px"
}