function PersonInfo({...args}) {
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: "25px"}}>
            <img src={args.src} style={{height: args.height, width: args.width}}/>
            <label style={{fontWeight: "bold", fontSize: "20px"}}>{args.name}</label>
            <p>{args.description}</p>
        </div>
    )
}

export default PersonInfo;